<template>
  <div class="station-con">
    <NavBar fixed title="油站详情" @click-left="goBack" :left-arrow="isWeixin_status == false"></NavBar>
    <div class="station-title">
      <div class="station-title-img">
        <VanImage widt="48" height="48" :src="store_img" alt fit="fill" />
      </div>
      <div class="station-title-main">
        <span class="title-text-1">{{storeInfo.name}}</span>
        <p class="title-text-2">{{storeInfo.area}}{{storeInfo.address}}</p>
      </div>
      <div class="station-title-map">
        <span class="iconfont icondaohang" @click="goAddress(storeInfo)"></span>
        <span v-if="dis_km">距您{{dis_km}}km</span>
      </div>
    </div>

    <div class="station-main">
      <div class="station-main-left">
        <span class="oil-text-1">实时油价</span>
        <div class="station-main-bot">
          <span class="oil-text-2">{{store_oil.mktprice}}</span>
          <span class="oil-text-3">原价</span>
        </div>
      </div>
      <div class="station-main-center">
        <div class="station-main-top">
          <swipe :loop="false" :width="55" :show-indicators="false">
            <template v-for="(item, idx) in storeInfo.goods">
              <swipe-item :key="idx"><p :class="oilstatus == idx ? 'active':''" @click="setOil(item, idx)">{{item.name}}</p></swipe-item>
            </template>
          </swipe>
          
        </div>
        <div class="station-right-bot">
          <div class="money-item">
            <span>{{store_oil.price}}</span>
            <span>油站价</span>
          </div>
          <div class="money-item">
            <span>{{store_oil.unit}}</span>
            <span>单位</span>
          </div>
        </div>
      </div>
    </div>

    <div class="station-order">
      <div class="station-tab">
        <div
          :class="[nowIdx == idx ? 'active-color':'']"
          v-for="(item, idx) in tabs"
          :key="idx"
          @click="nowIdx = idx"
        >
          <span>{{item}}</span>
          <transition name="van-fade">
            <div v-if="nowIdx == idx" :class="[nowIdx == idx ? 'border-active':'']"></div>
          </transition>
        </div>
      </div>
      <div class="station-order-list">
        <swiper :options="swiperOption">
          <swiperSlide v-for="(item, idx) in orderArr" :key="idx">
            <div class="station-item">
              <div class="station-item-left">
                <img v-lazy="item.user.headimgurl" alt />
              </div>
              <div class="station-item-main">
                <span>{{item.user.sign_mobile}}</span>
                <div class="item-main-con">
                  <div>
                    <span>{{item.payment_time | changTime}}</span>
                    <span>
                      <span>消费</span>
                      <span class="icon-money">{{item.money}}</span>
                    </span>
                  </div>
                  <p>
                    积分抵扣
                    <span class="icon-money2">{{item.dec_point_money}}</span>
                  </p>
                </div>
              </div>
            </div>
          </swiperSlide>
        </swiper>
      </div>
    </div>

    <div class="station-bottom">
      <div class="station-bottom-money">
        优惠
        <span style="color:#4b7cf5">{{store_oil.price}}</span>元/升
      </div>
      <router-link
        :to="'cardpay?type=0&store_id=' + storeInfo.store_id + '&room_id=' + storeInfo.id"
      >
        <div class="station-bottom-btn">
          <span>优惠加油</span>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import CreditShop from "@/api/creditshop";
import Store from "@/api/store";
import wx from "weixin-js-sdk";
import { NavBar, Image,Swipe, SwipeItem } from "vant";
import "swiper/dist/css/swiper.css";
import { formatTime } from "@/utils/timeformat";
import { goWxConfigJsApi } from "@/utils/wxpay";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    swiper,
    swiperSlide,
    NavBar,
    VanImage: Image,
    Swipe, SwipeItem
  },
  data() {
    return {
      nowIdx: 0,
      tabs: ["实时订单"],
      storeInfo: {},
      oilstatus: "",
      swiperOption: {
        observer: true,
        observeParents: true,
        direction: "vertical",
        loop: true,
        height: 60,
        speed: 2000,
        preventClicks: false,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      },
      dis_km: "",
      store_img: "",
      store_oil: {
        price: "",
        mktprice: "",
        unit: ""
      },
      orderArr: []
    };
  },
  filters: {
    changTime: function(value) {
      return formatTime(value);
    }
  },
  created() {
    if (this.isWeixin_status) {
      // 微信配置
      goWxConfigJsApi("openLocation, getLocation");
    }
  },
  mounted() {
    this.$nextTick(() => {
      const { id, store_id, km } = this.$route.query;
      if (km) {
        this.dis_km = km;
      }
      this.getStoreInfo(id, store_id);
    });
  },
  methods: {
    // 订单列表
    async getOrder() {
      try {
        const res = await CreditShop.oilOrderList(2, 1, 1, this.storeInfo.id);
        if (res.code == 200) {
          if (res.data.length > 0) {
            res.data.forEach(el => {
              this.orderArr.push(el);
            });
          } else {
            throw "无订单数据...";
          }
        } else {
          throw res.msg;
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    goAddress(item) {
      if (this.isWeixin_status) {
        wx.openLocation({
          latitude: item.lat, // 纬度，浮点数，范围为90 ~ -90
          longitude: item.lng, // 经度，浮点数，范围为180 ~ -180。
          name: item.area, // 位置名
          address: "", // 地址详情说明
          scale: 16, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl: "" // 在查看位置界面底部显示的超链接,可点击跳转
        });
      } else {
        this.$toast("请在微信环境中打开...");
      }
    },
    setOil(item, idx) {
      this.oilstatus = idx;
      this.store_oil.price = item.price;
      this.store_oil.mktprice = item.mktprice;
      this.store_oil.unit = item.unit;
    },
    async getStoreInfo(id, store_id) {
      const res = await Store.storeDes(id, store_id);
      if (res.code == 200) {
        this.storeInfo = res.data;
        this.store_img = res.data.image[0];
        this.store_oil = res.data.goods[0];
        // 获取列表
        this.$nextTick(() => {
          this.getOrder();
        });
      }
    }
  }
};
</script>
<style >
.swiper-wrapper {
  transition-timing-function: linear !important;
}
</style>
<style lang="scss" scoped>
.station-con {
  width: 93.6%;
  height: 100%;
  padding-top: 50px;
  margin: 0 auto;
  overflow: hidden;
  .station-title,
  .station-main,
  .station-order {
    height: 98px;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 18px 0 20px;
    .station-title-img {
      height: 48px;
      width: 48px;
      background: rebeccapurple;
    }
    .station-title-main {
      min-height: 60px;
      flex: 1;
      display: flex;
      margin-left: 16px;
      flex-direction: column;
      justify-content: space-between;
      .title-text-1 {
        font-size: 16px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 22px;
      }
      .title-text-2 {
        padding-right: 10px;
        word-wrap: break-word;
      }
      .title-text-3 {
        width: 64px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        color: #ff6d38;
        border-radius: 3px;
        border: 1px solid #ff6d38;
      }
    }
    .station-title-map {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .icondaohang {
        color: #000;
        font-size: 30px !important;
      }
    }
  }
  .station-main {
    position: relative;
    margin-top: 10px;
    height: 142px;
    padding: 20px 18px 27px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .station-main-left {
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      .oil-text-1 {
        color: #333333;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
      }
      .station-main-bot {
        height: 50px;
        display: flex;
        justify-content: space-between;
        
        flex-direction: column;
        .oil-text-2 {
          font-size: 24px;
          color: #999999;
          flex-wrap: 400;
        }
        .oil-text-3 {
          text-align: center;
        }
        .oil-text-2:before {
          position: relative;
          font-size: 14px;
          content: "\A5";
        }
      }
    }
    .station-main-center {
      flex: 1;
      height: 100%;
      display: flex;
      padding-left: 40px;
      flex-direction: column;
      justify-content: space-between;
      .station-main-top {
        display: flex;
        width: 210px;
        margin-left: -10px;
        p {
          display: block;
          // width:38px;
          padding: 0 5px;
          height: 22px;
          border-radius: 16px;
          text-align: center;
          line-height: 22px;
          margin-left: 10px;
          color: rgba(153, 153, 153, 1);
          border: 1px solid rgba(153, 153, 153, 1);
        }
        .active {
          border: 1px solid #4b7cf5;
          color: #4b7cf5;
        }
      }
      .station-right-bot {
        display: flex;
        height: 50px;
        justify-content: space-between;
        .money-item {
          height: 50px;
          display: flex;
          color: #333333;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          span:nth-child(1) {
            font-size: 22px;
          }
        }
      }
    }
  }
  .station-order {
    position: relative;
    margin-top: 10px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .station-tab {
      width: 56%;
      height: 60px;
      display: flex;
      position: relative;
      align-items: center;
      font-size: 18px;
      justify-content: space-between;
      padding: 0 50px;
      border-bottom: 1px solid #eeeeee;
      color: rgba(51, 51, 51, 0.6) !important;
      font-weight: 600 !important;
      div {
        position: relative;
        .border-active {
          position: absolute;
          bottom: -10px;
          width: 54px;
          height: 3px;
          left: 50%;
          margin-left: -27px;
          background: rgba(75, 124, 245, 1);
          border-radius: 4px;
        }
      }
      .active-color {
        color: #333333;
      }
    }
    .station-order-list {
      width: 100%;
      display: flex;
      padding-top: 16px;
      justify-content: space-between;
      .station-item {
        width: 310px;
        height: 62px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .station-item-left {
          width: 32px;
          height: 32px;
          border-radius: 16px;
          background: #ff6d38;
          img {
            width: 100%;
            height: 100%;
            border-radius: 16px;
          }
        }
        .station-item-main {
          flex: 1;
          display: flex;
          height: 100%;
          padding-top: 10px;
          padding-left: 10px;
          flex-direction: column;
          justify-content: space-between;
          border-bottom: 1px solid #eeeeee;
          .item-main-con {
            display: flex;
            color: #333333;
            padding-bottom: 10px;
            justify-content: space-between;
            .icon-money2 {
              color: #d9455f;
              flex-wrap: 400;
            }
            .icon-money2:before {
              position: relative;
              font-size: 10px;
              content: "\A5";
            }
            div {
              width: 165px;
              display: flex;
              justify-content: space-between;
              .icon-money {
                color: #333333;
                flex-wrap: 400;
              }
              .icon-money:before {
                position: relative;
                font-size: 10px;
                content: "\A5";
              }
            }
          }
        }
      }
    }
  }
  .station-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 0 18px;
    z-index: 9999;
    .station-bottom-money {
      color: #333333;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }
    .station-bottom-btn {
      width: 188px;
      height: 40px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      line-height: 40px;
      background: rgba(75, 124, 245, 1);
      border-radius: 20px;
    }
  }
}
</style>
